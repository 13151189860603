dmx.Component('calendar-source', {

    extends: 'calendar-source-base',

    constructor: function(node, parent, source) {
        this.parseEvents = this.parseEvents.bind(this);

        dmx.Component('calendar-source-base').call(this, node, parent);
    },

    attributes: {
        'events': {
            type: Array,
            default: []
        },

        'event-id': {
            type: String,
            default: 'id'
        },

        'event-title': {
            type: String,
            default: 'title'
        },

        'event-url': {
            type: String,
            default: 'url'
        },

        'event-start': {
            type: String,
            default: 'start'
        },

        'event-end': {
            type: String,
            default: 'end'
        },

        'event-all-day': {
            type: String,
            default: 'allDay'
        },

        'event-days-of-week': {
            type: String,
            default: 'daysOfWeek'
        },

        'event-start-time': {
            type: String,
            default: 'startTime'
        },

        'event-end-time': {
            type: String,
            default: 'endTime'
        },

        'event-start-recur': {
            type: String,
            default: 'startRecur'
        },

        'event-end-recur': {
            type: String,
            default: 'endRecur'
        },

        'event-group-id': {
            type: String,
            default: 'groupId'
        },

        'event-rendering': {
            type: String,
            default: 'rendering'
        },

        'event-color': {
            type: String,
            default: 'color'
        },

        'event-background-color': {
            type: String,
            default: 'backgroundColor'
        },

        'event-border-color': {
            type: String,
            default: 'borderColor'
        },

        'event-text-color': {
            type: String,
            default: 'textColor'
        },

        'event-class-name': {
            type: String,
            default: 'className'
        },

        'event-editable': {
            type: String,
            default: 'editable'
        },

        'event-overlap': {
            type: String,
            default: 'overlap'
        },

        'event-extended-props': {
            type: String,
            default: '$value'
        }
    },

    parseEvents: function(info, success, failure) {
        this.events = [];

        if (Array.isArray(this.props.events)) {
            dmx.repeatItems(this.props.events).forEach(function(data) {
                var scope = new dmx.DataScope(data, this);
                var event = {};

                for (var prop in this.props) {
                    if (prop.indexOf('event-') === 0) {
                        var value = dmx.parse(this.props[prop], scope);

                        if (value != null) {
                            event[this.toCamelCase(prop.substr(6))] = value;
                        }
                    }
                }

                this.events.push(event);
            }, this);
        }

        this.children.forEach(function(child) {
            if (child instanceof dmx.Component('calendar-event')) {
                var event = { id: child.name };

                for (var prop in child.props) {
                    if (child.props[prop] != null) {
                        event[this.toCamelCase(prop)] = child.props[prop];
                    }
                }

                this.events.push(event);
            }
        }, this);

        success(this.events);
        
        this.set('events', this.events);
    },

    render: function() {
        if (!this.calendar) return;

        this.$parse();

        var props = {};

        for (var prop in this.props) {
            if (this.props[prop] != null) {
                props[this.toCamelCase(prop)] = this.props[prop];
            }
        }
        
        this.calendar.addEventSource(Object.assign({
            id: this.name
        }, props, {
            events: this.parseEvents
        }));

        this.set('id', this.name);
    },

    update: function(props) {
        if (!dmx.equal(props.events, this.props.events)) {
            this.refetch();
        }
    }

});