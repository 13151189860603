dmx.Component('calendar-source-google', {

    extends: 'calendar-source-base',

    constructor: function(node, parent, source) {
        this.onSuccess = this.onSuccess.bind(this);
        this.onFailure = this.onFailure.bind(this);

        dmx.Component('calendar-source-base').call(this, node, parent);
    },

    attributes: {
        'google-calendar-api-key': {
            type: String,
            default: null
        },

        'google-calendar-id': {
            type: String,
            default: null
        },
    },

    events: {
        success: Event,
        failure: Event
    },

    onSuccess: function() {
        this.dispatchEvent('success');
    },

    onFailure: function(err) {
        this.dispatchEvent('failure');
    },

    render: function() {
        if (!this.calendar) return;

        this.$parse();

        var props = {};

        for (var prop in this.props) {
            if (this.props[prop] != null) {
                props[this.toCamelCase(prop)] = this.props[prop];
            }
        }
        
        this.calendar.addEventSource(Object.assign({
            id: this.name,
            success: this.onSuccess,
            failure: this.onFailure
        }, props));

        this.set('id', this.name);
    }

});